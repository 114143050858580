@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.search-container {
    .search {
        height: 40px;
    }

    .MuiIconButton-root {
        padding: 4px;
    }

    .MuiInputBase-root {
        // border-radius: 8px;
        background-color: var(--background-color-accented);

        & when (var(--theme) = dark) {
            background-color: var(--background-color);
        }
    }

    svg {
        color: var(--brand-color);
        width: 25px;
        height: 25px;
    }

    .is-empty {
        .broom-icon {
            color: var(--icon-button-color-disabled);
        }
    }
}
